.wrapper {
	.title {
		color: #323037;
		font-size: 64px;
		line-height: 70px;
		margin: 0 auto 75px;
		max-width: 514px;
		text-align: center;
		@media screen and (max-width: 992px) {
			font-size: calc(28px + 36 * ((100vw - 480px) / (992 - 480)));
			letter-spacing: -0.03em;
			line-height: calc(30px + 40 * ((100vw - 480px) / (992 - 480)));
		}
		@media screen and (max-width: 480px) {
			font-size: 28px;
			line-height: 30px;
			margin-bottom: 50px;
		}
	}

	.link {
		display: inline-block;
		margin-bottom: 16px;
		width: 100%;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
