@include font-face('TT Norms Pro', 'TTNormsPro-DmBd', 600);
@include font-face('TT Norms Pro', 'TTNormsPro-Normal', 400);
@include font-face('TT Norms Pro', 'TTNormsPro-Md', 500);

@font-face {
	font-display: swap;
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 400;
	src: url('../assets/fonts/Manrope-Regular.ttf') format('truetype');
}
