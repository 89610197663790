.wrapper {
  box-sizing: content-box;
  margin-bottom: 150px;
  max-width: 980px;
  @media screen and (max-width: 768px) {
    margin-bottom: 100px;
  }

  .title {
    color: #323037;
    font-size: 64px;
    letter-spacing: -0.03em;
    line-height: 70px;
    margin-bottom: 25px;
    max-width: 764px;
    padding: 0;
    text-align: center;
    @media screen and (max-width: 992px) {
      font-size: calc(28px + 36 * ((100vw - 480px) / (992 - 480)));
      line-height: calc(30px + 40 * ((100vw - 480px) / (992 - 480)));
    }
    @media screen and (max-width: 480px) {
      font-size: 28px;
      line-height: 30px;
    }
  }

  .subtitle {
    color: #323037;
    font-family: 'Manrope', sans-serif;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 75px;
    text-align: center;
    @media screen and (max-width: 992px) {
      font-size: calc(16px + 12 * ((100vw - 480px) / (992 - 480)));
      line-height: calc(19px + 27 * ((100vw - 480px) / (992 - 480)));
      margin-bottom: 50px;
    }
    @media screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .item {
    align-items: center;

    background: #fff;
    border-radius: 32px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 60px 40px;
    @media screen and (max-width: 768px) {
      border-radius: 16px;
      padding: 20px 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    span {
      color: #737373;
      display: block;
      font-size: 36px;
      line-height: 43px;
      max-width: 722px;
      @media screen and (max-width: 992px) {
        font-size: calc(16px + 20 * ((100vw - 480px) / (992 - 480)));
        line-height: calc(19px + 24 * ((100vw - 480px) / (992 - 480)));
        max-width: calc(100% - 120px);
      }
      @media screen and (max-width: 768px) {
        max-width: calc(100% - 68px);
      }
      @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    a {
      color: #39d292;
      text-decoration: underline;
    }

    svg {
      path {
        transition: ease 0.5s;
      }
      @media screen and (max-width: 768px) {
        height: 60px;
        width: 60px;
      }
    }

    &:hover {
      svg {
        path {
          fill: #39d292;
          transition: ease 0.5s;
          @media screen and (max-width: 768px) {
            fill: #eaeaea;
          }
        }
      }
    }
  }
}

// .wrapper {
//   .title {

//     color: #323037;
//     font-size: 64px;
//     letter-spacing: -0.03em;
//     line-height: 70px;
//     margin-bottom: 75px;
//     text-align: center;

// @media screen and (max-width: 992px) {
//   font-size: calc(28px + 36 * ((100vw - 480px) / (992 - 480)));
//   line-height: calc(30px + 40 * ((100vw - 480px) / (992 - 480)));
// }
// @media screen and (max-width: 480px) {

//   font-size: 28px;
//   line-height: 30px;
//   margin-bottom: 50px;
// }
//   }

//   .inner {
//     display: grid;
//     grid-gap: 75px 20px;
//     grid-template-columns: repeat(3, 1fr);
//     @media screen and (max-width: 1200px) {
//       grid-gap: 50px 16px;
//     }
//     @media screen and (max-width: 992px) {
//       grid-gap: 32px 16px;
//       grid-template-columns: repeat(2, 1fr);
//     }
//     @media screen and (max-width: 768px) {
//       grid-gap: 32px;
//       grid-template-columns: repeat(1, 1fr);
//     }
//   }
// }
