@mixin font-face($name, $file, $weight: 400, $style: normal) {
	@font-face {
		font-display: swap;
		font-family: '#{$name}';
		font-style: $style;
		font-weight: $weight;
		// src: url('../assets/fonts/#{$file}.eot');
		src: url('../assets/fonts/#{$file}.woff2') format('woff2'),
			url('../assets/fonts/#{$file}.woff') format('woff'),
			url('../assets/fonts/#{$file}.eot?#iefix') format('embedded-opentype'),
			url('../assets/fonts/#{$file}.ttf') format('truetype');
	}
}
