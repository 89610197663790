.wrapper {
  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 1334px;
    padding: 0 22px 20px;
    @media screen and (max-width: 1200px) {
      padding: 0 22px 32px;
    }
    @media screen and (max-width: 768px) {
      display: block;
      max-width: 480px;
      padding: 0 16px 32px;
    }
  }

  .logo {
    margin-right: 10px;
    @media screen and (max-width: 992px) {
      max-height: calc(30px + 18 * ((100vw - 768px) / (992 - 768)));
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
      margin-right: 0;
      max-height: 30px;
    }
  }

  .menu {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      margin-bottom: -15px;
    }
    @media screen and (max-width: 480px) {
      display: block;
    }

    li {
      margin-right: 32px;
      @media screen and (max-width: 992px) {
        margin-right: calc(15px + 17 * ((100vw - 768px) / (992 - 768)));
      }
      @media screen and (max-width: 768px) {
        margin-bottom: 15px;
        margin-right: 15px;
      }
      @media screen and (max-width: 480px) {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        color: #323037;
        @media screen and (max-width: 992px) {
          font-size: calc(16px + 2 * ((100vw - 480px) / (992 - 480)));
          line-height: calc(19px + 2 * ((100vw - 480px) / (992 - 480)));
        }
        @media screen and (max-width: 480px) {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
  }
}
