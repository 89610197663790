.smile {
	display: block;
	height: 100%;
	margin: -25px auto 75px;
	max-height: 160px;
	width: auto;
	@media screen and (max-width: 992px) {
		margin: -25px auto 50px;
		max-height: calc(120px + 40 * ((100vw - 480px) / (992 - 480)));
	}
	@media screen and (max-width: 480px) {
		margin: -20px auto 30px;
		max-height: 120px;
	}
}

.text {
	color: #bab9bb;
	font-size: 48px;
	line-height: 58px;
	margin: 0 auto;
	max-width: 700px;
	@media screen and (max-width: 992px) {
		font-size: calc(28px + 20 * ((100vw - 480px) / (992 - 480)));
		line-height: calc(30px + 28 * ((100vw - 480px) / (992 - 480)));
	}
	@media screen and (max-width: 480px) {
		font-size: 28px;
		line-height: 30px;
		text-align: center;
	}
	//   font-size: calc(34px + 14 * ((100vw - 480px) / (992 - 480)));
	//   line-height: calc(41px + 17 * ((100vw - 480px) / (992 - 480)));
	// }
	// @media screen and (max-width: 480px) {
	//   font-size: 34px;
	//   line-height: 41px;

	a {
		color: #323037;
		display: inline-block;
		font-weight: 600;
		margin-left: 10px;

		transition: color ease 0.5s;
		@media screen and (max-width: 480px) {
			display: block;
			margin-left: 0;
		}

		&:hover {
			color: #737373;

			transition: color ease 0.5s;
		}
	}
}
