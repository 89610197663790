.wrapper {
  margin-bottom: 150px;
  @media screen and (max-width: 768px) {
    margin-bottom: 100px;
  }

  &.container {
    box-sizing: content-box;
    max-width: 980px;
  }

  .title {

    color: #323037;
    font-size: 64px;
    letter-spacing: -0.03em;
    line-height: 70px;
    margin-bottom: 25px;
    text-align: center;
    @media screen and (max-width: 992px) {
      font-size: calc(28px + 36 * ((100vw - 480px) / (992 - 480)));
      line-height: calc(30px + 40 * ((100vw - 480px) / (992 - 480)));
    }
    @media screen and (max-width: 480px) {
      font-size: 28px;
      line-height: 30px;
    }
  }

  .subtitle {

    color: #323037;
    font-family: 'Manrope', sans-serif;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 75px;
    max-width: 835px;
    padding: 0;
    text-align: center;
    @media screen and (max-width: 992px) {

      font-size: calc(16px + 12 * ((100vw - 480px) / (992 - 480)));
      line-height: calc(19px + 27 * ((100vw - 480px) / (992 - 480)));
      margin-bottom: 50px;
    }
    @media screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .item {
    align-items: start;

    background: transparent;

    border-radius: 32px;

    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 60px 40px;
    position: relative;

    transition: background ease 0.5s;
    @media screen and (max-width: 992px) {
      display: block;
    }

    @media screen and (max-width: 768px) {

      border-radius: 16px;
      padding: 20px 32px 20px 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-title {

      color: #323037;
      font-size: 36px;
      letter-spacing: -0.03em;
      line-height: 42px;
      max-width: 202px;

      pointer-events: none;
      @media screen and (max-width: 992px) {

        font-size: calc(16px + 20 * ((100vw - 480px) / (992 - 480)));
        line-height: calc(19px + 23 * ((100vw - 480px) / (992 - 480)));
        margin-bottom: 5px;
        max-width: inherit;
        padding-right: 60px;
      }
      @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &-text {

      color: #323037;
      font-size: 28px;
      letter-spacing: -0.03em;
      line-height: 36px;
      max-width: 450px;

      pointer-events: none;
      @media screen and (max-width: 992px) {

        color: #737373;
        font-size: calc(16px + 12 * ((100vw - 480px) / (992 - 480)));
        line-height: calc(19px + 17 * ((100vw - 480px) / (992 - 480)));
        max-width: inherit;
      }
      @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    img {
      height: auto;
      left: 253px;
      max-width: 220px;
      opacity: 0;

      pointer-events: none;
      position: absolute;
      top: -20px;

      transform: rotate(-30deg);

      transition: ease 0.5s opacity;

      width: 100%;

      @media screen and (max-width: 992px) {
        left: inherit;

        max-width: 120px;
        right: -5px;
        top: -30px;

        transform: rotate(15deg);
      }
      @media screen and (max-width: 480px) {

        max-width: 94px;
        right: -10px;
        top: -10px;
      }
    }

    &:hover,
    &.hover {
      background: #fff;

      transition: background ease 0.5s;

      img {
        opacity: 1;

        transition: ease 0.5s opacity;
      }
    }
    @media screen and (max-width: 768px) {
      background: #fff;

      img {
        opacity: 1;
      }
    }
  }
}
