.wrapper {
	margin-bottom: 150px;
	padding-top: 100px;

	@media screen and (max-width: 768px) {
		margin-bottom: 100px;
		padding-top: 80px;
	}

	.container {
		background: #fff;
		border-radius: 32px;
		max-width: 980px;
		padding: 50px;
		@media screen and (max-width: 768px) {
			background: transparent;
			padding: 0 16px;
		}

		.title {
			color: #323037;
			font-size: 64px;
			letter-spacing: -0.03em;
			line-height: 70px;
			margin-bottom: 40px;
			text-align: center;
			@media screen and (max-width: 992px) {
				font-size: calc(28px + 36 * ((100vw - 480px) / (992 - 480)));
				line-height: calc(30px + 40 * ((100vw - 480px) / (992 - 480)));
			}
			@media screen and (max-width: 480px) {
				font-size: 28px;
				line-height: 30px;
			}
		}

		.block {
			margin-bottom: 50px;
		}

		.text {
			color: #3d3935;
			font-size: 15px;
			margin-bottom: 20px;

			a {
				color: #39d292;
			}
		}

		.rules {
			margin-left: 50px;
		}

		.rule {
			color: #3d3935;
			font-size: 15px;
			margin-bottom: 20px;

			span {
				font-weight: 600;
			}
		}

		.blockTitle {
			color: #3d3935;
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 20px;
		}
	}
}
