.papper {
  display: block;
  height: 100%;
  margin: 0 auto;
  margin-top: -25px;
  max-height: 160px;
  width: auto;
  @media screen and (max-width: 992px) {
    max-height: calc(120px + 40 * ((100vw - 480px) / (992 - 480)));
  }
  @media screen and (max-width: 480px) {
    max-height: 120px;
  }
}

// .smile {
//   display: block;
//   height: 100%;
//   margin: -25px auto 75px;
//   max-height: 160px;
//   width: auto;
//   @media screen and (max-width: 992px) {
//     margin: -25px auto 50px;
//     max-height: calc(120px + 40 * ((100vw - 480px) / (992 - 480)));
//   }
//   @media screen and (max-width: 480px) {
//     margin: -20px auto 30px;
//     max-height: 120px;
//   }
// }
