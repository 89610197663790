@import 'normalize';

@import 'mixins';
@import 'fonts';

@import 'theme';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

body {
	background: #f5f5f5;
	color: #323037;
	font-family: 'TT Norms Pro', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	font-size: 18px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	text-size-adjust: none;
	text-size-adjust: none;
}

.main-wrapper {
	max-width: 100vw;
	overflow-x: hidden;
	width: 100%;
}

// * {
// 	font-family: 'TT Norms Pro';
// 	font-style: normal;
// 	font-weight: 400;
//     color: #323037;
//     font-size: 18px;
//     line-height: 22px;
// }
