.wrapper {
  align-content: space-between;
  display: grid;
  grid-gap: 80px;
  grid-template-rows: auto auto;
  @media screen and (max-width: 992px) {
    grid-gap: 60px;
  }

  .image {

    border-radius: 16px;
    height: 309px;
    margin-bottom: 24px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    @media screen and (max-width: 1200px) {
      height: 256px;
    }
    @media screen and (max-width: 480px) {
      height: 180px;
    }
  }

  .status {

    color: #bab9bb;
    font-size: 24px;
    letter-spacing: -0.03em;
    line-height: 29px;
    margin-bottom: 8px;
    @media screen and (max-width: 1200px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .title {
    color: #323037;
    font-size: 32px;
    letter-spacing: -0.03em;
    line-height: 38px;
    @media screen and (max-width: 1200px) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  .more {

    border-top: 1px solid #d9d9d9;

    color: #bab9bb;
    font-size: 24px;
    letter-spacing: -0.03em;
    line-height: 29px;
    padding-top: 15px;

    transition: ease 0.5s;
  }

  &:hover {
    .more {

      border-top: 1px solid #39d292;
      color: #39d292;

      transition: ease 0.5s;
    }
  }

  // .title {
  // 	font-size: 64px;
  // 	line-height: 70px;
  // 	text-align: center;
  // 	letter-spacing: -0.03em;
  // 	color: #323037;
  // 	margin-bottom: 75px;

  // 	@media screen and (max-width: 992px) {
  // 	  font-size: calc(28px + 36 * ((100vw - 480px) / (992 - 480)));
  // 	  line-height: calc(30px + 40 * ((100vw - 480px) / (992 - 480)));
  // 	}
  // 	@media screen and (max-width: 480px) {
  // 	  font-size: 28px;
  // 	  line-height: 30px;
  // 	  margin-bottom: 50px;
  // 	}

  // }
}
