.wrapper {
  box-sizing: content-box;
  margin-bottom: 150px;
  max-width: 878px;
  @media screen and (max-width: 768px) {
    margin-bottom: 100px;
  }

  .title {

    color: #323037;
    font-size: 64px;
    letter-spacing: -0.03em;
    line-height: 70px;
    margin-bottom: 25px;
    padding: 0;
    text-align: center;
    @media screen and (max-width: 992px) {
      font-size: calc(28px + 36 * ((100vw - 480px) / (992 - 480)));
      line-height: calc(30px + 40 * ((100vw - 480px) / (992 - 480)));
    }
    @media screen and (max-width: 480px) {
      font-size: 28px;
      line-height: 30px;
    }
  }

  .subtitle {

    color: #323037;
    font-family: 'Manrope', sans-serif;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 75px;
    max-width: 696px;
    text-align: center;
    @media screen and (max-width: 992px) {

      font-size: calc(16px + 12 * ((100vw - 480px) / (992 - 480)));
      line-height: calc(19px + 27 * ((100vw - 480px) / (992 - 480)));
      margin-bottom: 50px;
    }
    @media screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .inner {
    display: grid;
    grid-gap: 100px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    @media screen and (max-width: 992px) {
      grid-gap: 20px;
    }
    @media screen and (max-width: 768px) {
      grid-gap: 50px;
      grid-template-columns: repeat(1, 1fr);
    }

    &-title {

      color: #bab9bb;
      font-size: 48px;
      font-weight: 600;
      line-height: 50px;
      min-height: 100px;
      @media screen and (max-width: 992px) {
        font-size: calc(34px + 14 * ((100vw - 480px) / (992 - 480)));
        line-height: calc(41px + 17 * ((100vw - 480px) / (992 - 480)));
      }
      @media screen and (max-width: 768px) {
        margin-bottom: 25px;
        min-height: inherit;

        text-align: center;
      }
      @media screen and (max-width: 480px) {
        font-size: 34px;
        line-height: 41px;
      }
    }
  }

  .phone {
    margin-top: 28px;
  }

  .phone,
  .mail {

    color: #323037;
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
    margin-bottom: 4px;

    transition: ease 0.5s;

    &:hover {
      color: #737373;

      transition: ease 0.5s;
    }
    @media screen and (max-width: 992px) {
      font-size: calc(34px + 14 * ((100vw - 480px) / (992 - 480)));
      line-height: calc(41px + 17 * ((100vw - 480px) / (992 - 480)));
    }
    @media screen and (max-width: 768px) {
      display: block;
      margin-top: 0;

      text-align: center;
    }
    @media screen and (max-width: 480px) {
      font-size: 34px;
      line-height: 41px;
    }
  }

  form {
    input {

      background: #fff;
      border: 1px solid transparent;
      border-radius: 61px;

      color: #737373;
      color: #323037;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 25px;
      padding: 20px 24px;

      transition: ease 0.5s;
      width: 100%;

      &:placeholder {
        color: #737373;
      }
      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }

      &.error {
        border: 1px solid #f06d43;

        transition: ease 0.5s;
      }
    }

    button {

      background: #323037;
      border: 0;
      border: 2px solid #323037;
      border-radius: 40px;

      color: #fff;
      cursor: pointer;
      font-size: 20px;
      font-weight: 600;
      line-height: 18px;
      padding: 23px;
      text-align: center;

      transition: ease 0.5s;

      width: 100%;

      &:hover {

        background: transparent;
        border: 2px solid #323037;
        color: #323037;

        transition: ease 0.5s;
      }
    }
  }
}

.agree {

  color: #323037;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;
  padding: 8px 24px;
  @media screen and (max-width: 768px) {
    margin-top: 10px;
    padding: 0;

    text-align: center;
  }

  a {
    color: #737373;
    display: inline;
    text-decoration: underline;
  }
}
