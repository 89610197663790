.logo {
	display: block;
	height: 100%;
	max-height: 48px;
	width: auto;
}

.up {
	cursor: pointer;
}
