.wrapper {
	margin-bottom: 150px;
	@media screen and (max-width: 768px) {
		margin-bottom: 100px;
	}

	.container {
		> *:last-child {
			margin-bottom: 0 !important;
		}
	}

	.second-title {
		position: relative;

		div {
			font-size: 36px;

			font-weight: 500;
			letter-spacing: -0.03em;
			line-height: 43px;
			margin-bottom: 50px;
			max-width: 980px;
			padding: 0;

			@media screen and (max-width: 1400px) {
				max-width: 690px;
			}

			@media screen and (max-width: 992px) {
				font-size: calc(20px + 16 * ((100vw - 480px) / (992 - 480)));
				line-height: calc(24px + 19 * ((100vw - 480px) / (992 - 480)));
			}
			@media screen and (max-width: 480px) {
				font-size: 20px;
				line-height: 24px;
			}
		}

		span {
			align-items: center;

			color: #bab9bb;

			cursor: pointer;

			display: flex;
			font-size: 20px;
			left: 35px;
			line-height: 24px;
			position: absolute;
			top: 4px;

			svg {
				margin-right: 4px;
			}
			@media screen and (max-width: 1400px) {
				bottom: 0;
				left: 0;
				top: inherit;
			}
			@media screen and (max-width: 992px) {
				font-size: calc(16px + 4 * ((100vw - 480px) / (992 - 480)));
				line-height: calc(19px + 5 * ((100vw - 480px) / (992 - 480)));

				margin-bottom: 40px;
				position: inherit;
			}
			@media screen and (max-width: 480px) {
				font-size: 16px;
				line-height: 19px;
				margin-left: -16px;
			}
		}
	}

	.imageWrapper {
		margin-bottom: 150px;
		position: relative;

		width: 100%;
		@media screen and (max-width: 768px) {
			margin-bottom: 100px;
		}
		@media screen and (max-width: 480px) {
			margin-left: -16px;
			margin-right: -16px;
			width: calc(100% + 32px);
		}

		img {
			border-radius: 34px;
			display: block;
			height: auto;
			width: 100%;
			@media screen and (max-width: 480px) {
				height: 200px;
				object-fit: cover;
				object-position: center;
			}
		}

		.title {
			bottom: 0;

			color: #fff;
			font-size: 72px;
			font-weight: 600;
			left: 0;
			line-height: 86px;
			padding: 0 75px 45px;
			position: absolute;

			width: 100%;
			@media screen and (max-width: 1200px) {
				font-size: 48px;
				line-height: 58px;
			}
			@media screen and (max-width: 992px) {
				font-size: calc(28px + 20 * ((100vw - 480px) / (992 - 480)));

				font-weight: 400;
				letter-spacing: -0.03em;
				line-height: calc(30px + 28 * ((100vw - 480px) / (992 - 480)));
				padding: 0 40px 35px;
			}
			@media screen and (max-width: 480px) {
				font-size: 28px;
				line-height: 30px;
				padding: 0 26px 28px;
			}
		}
	}

	.text {
		color: #737373;
		font-size: 20px;
		letter-spacing: -0.03em;
		line-height: 26px;
		margin-bottom: 150px;
		max-width: 560px;
		padding: 0;
		white-space: pre-line;
		&.isWide {
			max-width: 992px;
		}

		.imageWithCaption {
			margin: 30px 0 0;
			img {
				margin: 0;
			}
			span {
				display: block;
				text-align: center;
				margin-top: 10px;
			}
		}

		.textWithImage {
			.block {
				display: grid;
				gap: 20px;
				img {
					margin: 0;
				}
				ul {
					list-style: inside;
					display: grid;
					grid-gap: 15px;
					margin-top: 15px;
				}
				> div {
					margin-top: 20px;
				}
			}
			.block1 {
				grid-template-columns: 1fr 500px;
			}
			.block2 {
				grid-template-columns: 500px 1fr;
			}
			@media screen and (max-width: 812px) {
				.block {
					img {
						max-width: 450px;
						justify-self: center;
					}
					> div {
						margin-top: 0px;
					}
				}
				.block1 {
					grid-template-columns: 1fr;
				}
				.block2 {
					grid-template-columns: 1fr;
				}
			}
		}

		@media screen and (max-width: 992px) {
			font-size: calc(14px + 6 * ((100vw - 480px) / (992 - 480)));
			line-height: calc(17px + 9 * ((100vw - 480px) / (992 - 480)));
		}
		@media screen and (max-width: 768px) {
			margin-bottom: 50px;
		}
		@media screen and (max-width: 480px) {
			font-size: 14px;
			line-height: 17px;
		}

		img {
			width: 100%;
			height: auto;
			margin: 30px 0;
			display: block;
			border-radius: 24px;
			@media screen and (max-width: 768px) {
				border-radius: 12px;
				margin: 20px 0;
			}
		}

		a {
			color: #39d292;
			text-decoration: underline;
		}
	}
}
