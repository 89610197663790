.wrapper {
	margin-bottom: 150px;
	width: 100vw;
	@media screen and (max-width: 768px) {
		margin-bottom: 100px;
	}

	.title {
		color: #323037;
		font-size: 64px;
		letter-spacing: -0.03em;
		line-height: 70px;
		margin-bottom: 25px;
		margin-top: -88px;
		padding-top: 88px;
		text-align: center;
		@media screen and (max-width: 768px) {
			margin-top: -50px;
			padding-top: 50px;
		}
		@media screen and (max-width: 992px) {
			font-size: calc(28px + 36 * ((100vw - 480px) / (992 - 480)));
			line-height: calc(30px + 40 * ((100vw - 480px) / (992 - 480)));
		}
		@media screen and (max-width: 480px) {
			font-size: 28px;
			line-height: 30px;
		}
	}

	.subtitle {
		color: #323037;
		font-family: 'Manrope', sans-serif;
		font-size: 28px;
		letter-spacing: -0.03em;
		line-height: 36px;
		margin-bottom: 75px;
		max-width: 696px;
		padding: 0;
		text-align: center;

		@media screen and (max-width: 992px) {
			font-size: calc(16px + 12 * ((100vw - 480px) / (992 - 480)));
			line-height: calc(19px + 27 * ((100vw - 480px) / (992 - 480)));
			margin-bottom: 50px;
		}
		@media screen and (max-width: 480px) {
			font-size: 16px;
			line-height: 19px;
		}
	}

	.inner {
		display: grid;
		grid-gap: 20px;
		grid-template-columns: repeat(3, 1fr);
	}

	.item {
		align-self: start;

		background: #fff;
		border-radius: 32px;
		padding: 40px;
		@media screen and (max-width: 768px) {
			border-radius: 16px;
			padding: 24px;
		}

		&-title {
			color: #323037;
			font-size: 32px;
			font-weight: 600;
			letter-spacing: -0.03em;
			line-height: 38px;
			margin-bottom: 20px;
			@media screen and (max-width: 992px) {
				font-size: calc(24px + 8 * ((100vw - 480px) / (992 - 480)));
				line-height: calc(29px + 9 * ((100vw - 480px) / (992 - 480)));
			}
			@media screen and (max-width: 480px) {
				font-size: 24px;
				line-height: 29px;
				margin-bottom: 16px;
			}
		}

		&-price-for {
			color: #737373;
			font-size: 20px;
			letter-spacing: -0.03em;
			line-height: 20px;
			margin-bottom: 48px;
			max-width: 140px;
			@media screen and (max-width: 992px) {
				font-size: calc(16px + 4 * ((100vw - 480px) / (992 - 480)));
				line-height: calc(19px + 1 * ((100vw - 480px) / (992 - 480)));
			}
			@media screen and (max-width: 480px) {
				font-size: 16px;
				line-height: 19px;
				margin-bottom: 24px;
			}
		}

		&-text {
			color: #737373;
			font-size: 20px;
			letter-spacing: -0.03em;
			line-height: 24px;
			margin-bottom: 24px;
			@media screen and (max-width: 992px) {
				font-size: calc(16px + 4 * ((100vw - 480px) / (992 - 480)));
				line-height: calc(19px + 5 * ((100vw - 480px) / (992 - 480)));
			}
			@media screen and (max-width: 480px) {
				font-size: 16px;
				line-height: 19px;
			}
		}

		&-price {
			color: #323037;
			font-size: 39px;
			font-weight: 600;
			line-height: 66px;
			margin-bottom: 8px;

			span {
				font-size: 32px;

				font-weight: 500;
				line-height: 38px;
				margin-left: 4px;
				padding-top: 4px;
				@media screen and (max-width: 992px) {
					font-size: 24px;
					line-height: 29px;
				}
			}
			@media screen and (max-width: 992px) {
				font-size: calc(40px + 15 * ((100vw - 480px) / (992 - 480)));
				line-height: calc(48px + 18 * ((100vw - 480px) / (992 - 480)));
			}
			@media screen and (max-width: 480px) {
				font-size: 40px;
				line-height: 48px;
			}
		}

		button {
			background: #323037;
			border: 0;
			border: 2px solid #323037;
			border-radius: 30px;

			color: #fff;
			cursor: pointer;
			font-size: 24px;
			font-weight: 500;
			line-height: 29px;
			margin-bottom: 48px;
			padding: 17.5px;

			transition: ease 0.5s;
			width: 100%;

			&:hover {
				background: transparent;
				border: 2px solid #323037;
				color: #323037;

				transition: ease 0.5s;
			}

			@media screen and (max-width: 992px) {
				font-size: calc(16px + 8 * ((100vw - 480px) / (992 - 480)));
				line-height: calc(19px + 10 * ((100vw - 480px) / (992 - 480)));
			}
			@media screen and (max-width: 480px) {
				font-size: 16px;
				line-height: 19px;
				margin-bottom: 32px;
				padding: 12px;
			}
		}

		&-list {
			div {
				display: grid;
				grid-gap: 4px;
				grid-template-columns: 32px 1fr;
				margin-bottom: 16px;
				@media screen and (max-width: 480px) {
					grid-template-columns: 20px 1fr;
				}

				&:last-child {
					margin-bottom: 0;
				}

				&.cross {
					color: #bab9bb;
					text-decoration-line: line-through;
				}

				span {
					color: #bab9bb;
					display: inline-block;
					font-size: 24px;
					line-height: 29px;
					@media screen and (max-width: 992px) {
						font-size: calc(16px + 8 * ((100vw - 480px) / (992 - 480)));
						line-height: calc(19px + 10 * ((100vw - 480px) / (992 - 480)));
					}
					@media screen and (max-width: 480px) {
						font-size: 16px;
						line-height: 19px;
					}
				}

				svg {
					@media screen and (max-width: 480px) {
						height: 20px;
						width: 20px;
					}
				}
			}
		}

		&.dark {
			background: #323037;

			button {
				background: #fff;

				border: 2px solid #fff;
				color: #323037;

				transition: ease 0.5s;

				&:hover {
					background: transparent;
					border: 2px solid #fff;
					color: #fff;

					transition: ease 0.5s;
				}
			}

			.item {
				&-title {
					color: #fff;
				}

				&-price-for {
					color: #bab9bb;
				}

				&-text {
					color: #bab9bb;
				}

				&-price {
					color: #fff;
					display: block;
					font-size: 39px;
					font-weight: 600;
					line-height: 66px;

					span {
						font-size: 32px;
						line-height: 38px;
						margin-left: 0;
						padding-top: 0;
						@media screen and (max-width: 992px) {
							font-size: 21px;
							line-height: 25px;
						}
					}
					@media screen and (max-width: 992px) {
						font-size: calc(26px + 13 * ((100vw - 480px) / (992 - 480)));
						line-height: calc(31px + 35 * ((100vw - 480px) / (992 - 480)));
					}
					@media screen and (max-width: 480px) {
						font-size: 26px;
						line-height: 31px;
					}
				}
			}
		}
	}

	.scroll-wrapper {
		@media screen and (max-width: 1400px) {
			margin: 0 -20px;
			overflow-x: scroll;
			padding: 0 20px;
			width: 100vw;
		}
		@media screen and (max-width: 768px) {
			margin: 0;
			overflow: hidden;
			padding: 0;
			width: 100%;
		}

		.inner {
			@media screen and (max-width: 1400px) {
				grid-template-columns: repeat(3, 440px);
				width: max-content;
			}
			@media screen and (max-width: 768px) {
				grid-template-columns: 1fr;
				width: 100%;
			}
		}
	}
}
