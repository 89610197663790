.wrapper {
  height: 522px;
  margin-bottom: 150px;
  max-width: 1360px;
  position: relative;

  width: 100vw;
  @media screen and (max-width: 768px) {
    height: 428px;
    margin-bottom: 100px;
    padding: 0;
  }

  .scene {
    bottom: 0;
    height: 100vh;
    left: 0;
    position: absolute;

    width: 100%;
    @media screen and (max-width: 992px) {
      pointer-events: none;
    }
  }

  // .item {
  //   border-radius: 32px;
  //   color: #323037;
  //   cursor: pointer;
  //   display: inline-block;
  //   font-size: 28px;
  //   letter-spacing: -0.03em;
  //   line-height: 34px;
  //   padding: 10px 24px;
  // }
  .title {

    color: #323037;
    font-size: 72px;
    font-weight: 600;
    left: 50%;
    letter-spacing: -0.03em;
    line-height: 86px;

    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 0;

    transform: translateX(-50%);

    width: 100%;
    z-index: 3;
    @media screen and (max-width: 992px) {
      font-size: calc(33px + 39 * ((100vw - 480px) / (992 - 480)));
      line-height: calc(41px + 45 * ((100vw - 480px) / (992 - 480)));
    }
    @media screen and (max-width: 480px) {
      font-size: 33px;
      line-height: 41px;
    }

    > div {

      border: 1px solid #323037;
      border-radius: 32px;

      box-sizing: content-box;

      color: #0cce94;
      height: 86px;
      margin: 0 auto;
      max-width: max-content;
      overflow: hidden;
      padding: 4px 24px;
      position: relative;

      transition: ease 1s;
      @media screen and (max-width: 992px) {
        height: calc(41px + 45 * ((100vw - 480px) / (992 - 480)));
      }

      @media screen and (max-width: 480px) {
        height: 41px;
        padding: 4px 12px;
      }
    }

    span {

      display: block;
      margin: 0 auto;
      max-width: max-content;
      position: relative;
      top: 0;

      transition: ease 1s;
      width: max-content;
    }
  }
}
