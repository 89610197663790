.wrapper {
	background: transparent;

	background: #fff;
	border-radius: 32px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
	padding: 60px 40px;

	transition: background ease 0.5s;
	@media screen and (max-width: 992px) {
		padding: calc(30px + 30 * ((100vw - 480px) / (992 - 480)))
			calc(20px + 20 * ((100vw - 480px) / (992 - 480)));
	}
	@media screen and (max-width: 480px) {
		margin-bottom: 15px;
		padding: 30px 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
	@media screen and (max-width: 479px) {
		background: #fff !important;

		display: block;
		overflow: hidden;
		position: relative;
	}

	&:hover {
		transition: background ease 0.5s;

		.stacks {
			.itemWrapper {
				img {
					opacity: 1;

					transition: opacity ease 0.5s;
				}
			}
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	.title {
		color: #323037;
		font-size: 36px;
		letter-spacing: -0.03em;
		line-height: 42px;
		max-width: 260px;
		@media screen and (max-width: 992px) {
			font-size: calc(24px + 12 * ((100vw - 480px) / (992 - 480)));
			line-height: calc(29px + 13 * ((100vw - 480px) / (992 - 480)));
		}
		@media screen and (max-width: 480px) {
			font-size: 24px;
			line-height: 29px;
		}
		@media screen and (max-width: 479px) {
			margin-bottom: 47px;
			max-width: calc(100% - 37px);
		}
	}

	.stacks {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: -8px;
		margin-right: -8px;
		max-width: 353px;
		@media screen and (max-width: 479px) {
			justify-content: start;
			margin-bottom: -5px;
			margin-right: -5px;
			max-width: inherit;
		}

		.itemWrapper {
			margin-bottom: 8px;

			margin-right: 8px;
			position: relative;
			@media screen and (max-width: 479px) {
				margin-bottom: 5px;

				margin-right: 5px;
				position: inherit;
			}

			img {
				bottom: calc(100% - 8px);
				left: 50%;
				max-width: calc(100% - 4px);
				opacity: 0;
				position: absolute;

				transform: translateX(-50%);

				transition: opacity ease 0.5s;

				width: 99px;
				z-index: 0;
				@media screen and (max-width: 479px) {
					bottom: inherit;
					left: inherit;
					opacity: 1;
					right: -29px;
					top: 16px;

					transform: rotate(-60deg);

					width: 84px;
				}
			}
		}

		.item {
			background: #eaeaea;
			border-radius: 46px;

			color: #323037;
			font-size: 24px;
			letter-spacing: -0.03em;
			line-height: 29px;

			padding: 6px 20px;
			position: relative;
			z-index: 2;
			@media screen and (max-width: 992px) {
				font-size: calc(16px + 8 * ((100vw - 480px) / (992 - 480)));
				line-height: calc(19px + 10 * ((100vw - 480px) / (992 - 480)));
			}
			@media screen and (max-width: 480px) {
				font-size: 16px;
				line-height: 19px;
				padding: 8px 15px;
			}
		}
	}
}
