.blur {
  backdrop-filter: blur(2px);

  background: rgba(217, 217, 217, 0.2);
  bottom: 0;

  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
  @media screen and (max-width: 992px) {
    display: block;
  }
}

.wrapper {

  backdrop-filter: blur(8px);

  left: 0;
  margin-bottom: 20px;
  position: relative;
  position: fixed;
  top: 0;

  width: 100%;
  z-index: 10;

  &.fixed {
    backdrop-filter: inherit;
    background: #f5f5f5;
  }

  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 1334px;
    padding: 20px 22px;

    &.small {
      max-width: 1024px;
    }
    @media screen and (max-width: 768px) {
      padding: 8px 16px;
    }
  }

  .logo {
    margin-right: 10px;
    @media screen and (max-width: 992px) {
      max-height: calc(30px + 18 * ((100vw - 768px) / (992 - 768)));
    }
    @media screen and (max-width: 768px) {
      max-height: 30px;
    }
  }

  .menu-wrapper {
    align-items: center;
    display: flex;

    > div {
      align-items: center;
      display: flex;
    }
    @media screen and (max-width: 992px) {

      background: #fff;

      display: block;
      display: flex;
      flex-direction: column;
      height: 100vh;
      justify-content: space-between;
      padding: 60px 16px 24px;
      position: fixed;
      right: -150vw;
      top: 0;

      transition: ease 0.5s;
      z-index: 10;

      > div {
        align-items: end;
        display: flex;
        flex-direction: column;
      }

      &.active {
        right: 0;

        transition: ease 0.5s;
      }
    }
  }

  .menu {
    align-items: center;
    display: flex;
    margin-right: 40px;
    @media screen and (max-width: 992px) {
      display: block;
      margin: 0;

      text-align: right;
    }

    li {
      margin-right: 32px;
      @media screen and (max-width: 992px) {
        margin-bottom: 5px;
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      a {

        border-radius: 32px;

        color: #323037;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        margin: 0 -16px;
        padding: 8px 16px;

        transition: ease 0.5s;
        @media screen and (max-width: 992px) {

          font-size: 32px;
          line-height: 38px;
          margin: 0;
          padding: 0;
        }

        &:hover,
        &.active {
          background: #fff;

          transition: ease 0.5s;

          @media screen and (max-width: 992px) {

            background: transparent;
            color: #39d292;
          }
        }
      }
    }
  }

  .button {
    align-items: center;

    border-radius: 30px;
    cursor: pointer;
    display: flex;
    font-size: 16px;

    font-weight: 600;
    line-height: 19px;
    padding: 10px 24px;

    svg {
      margin-left: 8px;

      path,
      rect {
        transition: ease 0.5s;
      }
    }

    &--try {

      background: #323037;
      border: 1px solid #323037;

      border: 2px solid #323037;

      color: #fff;
      margin-right: 20px;

      transition: ease 0.5s;
      @media screen and (max-width: 992px) {
        margin-bottom: 10px;
        margin-right: 0;
      }

      &:hover {

        background: transparent;
        border: 2px solid #323037;
        color: #323037;

        transition: ease 0.5s;

        svg {
          path,
          rect {

            fill: #323037;
            transition: ease 0.5s;
          }
        }
      }
    }

    &--login {

      background: transparent;
      border: 2px solid #323037;

      border: 2px solid #323037;
      color: #323037;

      transition: ease 0.5s;

      svg {
        path,
        rect {
          transition: ease 0.5s;
        }
      }

      &:hover {

        background: #323037;
        color: #fff;

        transition: ease 0.5s;

        svg {
          path,
          rect {

            fill: #fff;
            transition: ease 0.5s;
          }
        }
      }
    }
  }

  .burger-menu {

    cursor: pointer;

    display: none;
    height: 18px;
    position: relative;
    width: 24px;
    z-index: 20;
    @media screen and (max-width: 992px) {
      display: block;
    }

    span {

      background: #323037;
      border-radius: 12px;

      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      transition: ease 0.5s;
      width: 100%;

      &::after {

        background: #323037;
        border-radius: 12px;

        content: '';

        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        top: -8px;

        transition: ease 0.5s;
        width: 100%;
      }

      &::before {

        background: #323037;
        border-radius: 12px;
        bottom: -8px;

        content: '';

        display: block;
        height: 2px;
        left: 0;
        position: absolute;

        transition: ease 0.5s;
        width: 100%;
      }
    }

    &.active {
      span {
        background: transparent;
        transform: translateY(-50%);

        transition: ease 0.5s;

        &::after {
          top: 0;

          transform: rotate(-225deg);

          transition: ease 0.5s;
        }

        &::before {
          bottom: 0;

          transform: rotate(225deg);

          transition: ease 0.5s;
        }
      }
    }
  }
}
