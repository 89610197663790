.wrapper {
  margin-bottom: 150px;
  max-width: 100vw;
  padding-top: 150px;

  width: 100%;
  @media screen and (max-width: 768px) {
    margin-bottom: 100px;
    padding-top: 80px;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  .more {
    box-sizing: content-box;

    color: #bab9bb;
    font-size: 48px;
    font-weight: 600;
    line-height: 50px;
    margin-bottom: 150px;
    max-width: 878px;
    @media screen and (max-width: 992px) {
      font-size: calc(34px + 14 * ((100vw - 480px) / (992 - 480)));
      line-height: calc(41px + 9 * ((100vw - 480px) / (992 - 480)));
    }
    @media screen and (max-width: 480px) {
      font-size: 34px;
      line-height: 41px;
      text-align: center;
    }

    a {
      color: #39d292;

      opacity: 1;

      transition: opacity ease 0.5s;

      &:hover {
        opacity: 0.5;

        transition: opacity ease 0.5s;
      }
    }
  }
}
