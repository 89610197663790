.wrapper {
	margin-bottom: 150px;
	@media screen and (max-width: 768px) {
		margin-bottom: 100px;
	}

	.title {
		color: #323037;
		font-size: 64px;
		letter-spacing: -0.03em;
		line-height: 70px;
		margin-bottom: 25px;
		// Без подзаголовка
		margin-bottom: 75px;
		margin-top: -88px;
		padding-top: 88px;
		text-align: center;
		@media screen and (max-width: 768px) {
			margin-top: -50px;
			padding-top: 50px;
		}
		@media screen and (max-width: 992px) {
			font-size: calc(28px + 36 * ((100vw - 480px) / (992 - 480)));
			line-height: calc(30px + 40 * ((100vw - 480px) / (992 - 480)));
			// Без подзаголовка
			margin-bottom: 50px;
		}
		@media screen and (max-width: 480px) {
			font-size: 28px;
			line-height: 30px;
		}
	}

	.subtitle {
		color: #323037;
		font-family: 'Manrope', sans-serif;
		font-size: 28px;
		line-height: 36px;
		margin-bottom: 75px;
		text-align: center;
		@media screen and (max-width: 992px) {
			font-size: calc(16px + 12 * ((100vw - 480px) / (992 - 480)));
			line-height: calc(19px + 27 * ((100vw - 480px) / (992 - 480)));
			margin-bottom: 50px;
		}
		@media screen and (max-width: 480px) {
			font-size: 16px;
			line-height: 19px;
		}
	}

	.item {
		background: #fff;
		border-radius: 32px;
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;
		padding: 60px 40px;
		@media screen and (max-width: 992px) {
			background: transparent;
			display: grid;
			margin-bottom: 50px;
			padding: 0;
		}

		&:last-child {
			margin-bottom: 0 !important;
		}

		.text-wrapper {
			display: flex;
			flex-direction: column;
			grid-row: 2;
			justify-content: space-between;
		}

		&-title {
			color: #323037;
			font-size: 36px;
			letter-spacing: -0.03em;
			line-height: 40px;
			max-width: 230px;
			@media screen and (max-width: 992px) {
				font-size: calc(16px + 20 * ((100vw - 480px) / (992 - 480)));
				line-height: calc(19px + 23 * ((100vw - 480px) / (992 - 480)));
				margin-bottom: 10px;
				max-width: inherit;
				text-align: center;
			}
			@media screen and (max-width: 480px) {
				font-size: 16px;
				line-height: 19px;
			}
		}

		&-text {
			color: #737373;
			font-size: 28px;
			letter-spacing: -0.03em;
			line-height: 36px;
			max-width: 324px;
			@media screen and (max-width: 992px) {
				font-size: calc(16px + 12 * ((100vw - 480px) / (992 - 480)));
				line-height: calc(19px + 17 * ((100vw - 480px) / (992 - 480)));
				max-width: inherit;
				text-align: center;
			}
			@media screen and (max-width: 480px) {
				font-size: 16px;
				line-height: 19px;
			}
		}

		.image-wrapper {
			border-radius: 24px;
			padding: 56px 60px;
			@media screen and (max-width: 1200px) {
				padding: 88px 20px;
			}
			@media screen and (max-width: 992px) {
				margin: 0 auto 20px;
				max-width: 500px;
				padding: 50px 20px;
			}
			@media screen and (max-width: 768px) {
				border-radius: 12px;
			}
			@media screen and (max-width: 480px) {
				padding: 28px 6px;
			}

			img {
				border-radius: 16px;
				display: block;
				height: auto;
				width: 577px;
				height: 408px;
				object-fit: cover;
				object-position: center;
				@media screen and (max-width: 1200px) {
					width: 486px;
					height: 344px;
				}
				@media screen and (max-width: 992px) {
					max-width: 486px;
					width: 100%;
					height: auto;
				}
				@media screen and (max-width: 768px) {
					border-radius: 10px;
					min-width: 276px;
				}
				@media screen and (max-width: 360px) {
					width: 276px;
					height: 157px;
				}
			}
		}
	}
}
