.wrapper {
	align-items: center;

	background: rgba(0, 0, 0, 0.6);

	display: flex;
	height: 100vh;
	justify-content: center;
	left: 0;
	opacity: 0;
	padding: 16px;

	pointer-events: none;
	position: fixed;
	top: 0;

	width: 100vw;
	z-index: 30;
	z-index: 0;

	&.active {
		opacity: 1;

		pointer-events: inherit;

		z-index: 30;
	}

	.agree {
		color: #323037;
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 30px;
		padding: 8px 24px;
		@media screen and (max-width: 768px) {
			margin-top: 10px;
			padding: 0;

			text-align: center;
		}

		a {
			color: #737373;
			display: inline;
			text-decoration: underline;
		}
	}

	.form {
		background: #f5f5f5;
		border-radius: 16px;

		max-width: 430px;
		padding: 25px 30px 35px;
		position: relative;

		@media screen and (max-width: 360px) {
			padding: 20px;
		}

		.close {
			cursor: pointer;
			position: absolute;
			right: -15px;
			top: -15px;
		}

		.title {
			color: #323037;
			font-size: 24px;
			font-weight: 500;
			line-height: 24px;
			margin-bottom: 35px;
			@media screen and (max-width: 768px) {
				margin-bottom: 35px;
			}
			@media screen and (max-width: 360px) {
				margin-bottom: 20px;
			}
		}

		input {
			background: #fff;

			border: 1px solid transparent;
			border-radius: 61px;

			color: #737373;
			color: #323037;
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 25px;
			padding: 20px 24px;
			width: 100%;
			@media screen and (max-width: 768px) {
				margin-bottom: 20px;
			}
			@media screen and (max-width: 360px) {
				margin-bottom: 15px;
			}

			&:placeholder {
				color: #737373;
			}

			&.error {
				border: 1px solid #f06d43;

				transition: ease 0.5s;
			}
		}

		button {
			background: #323037;
			border: 0;
			border: 2px solid #323037;
			border-radius: 40px;

			color: #fff;
			cursor: pointer;
			font-size: 20px;
			font-weight: 600;
			line-height: 18px;
			padding: 23px;
			text-align: center;

			transition: ease 0.5s;
			width: 100%;

			&:hover {
				background: transparent;
				border: 2px solid #323037;
				color: #323037;

				transition: ease 0.5s;
			}
		}
	}
}
