.wrapper {
	display: grid;
	grid-template-rows: 1fr auto;
	min-height: 100vh;

	padding-top: 88px;
	@media screen and (max-width: 768px) {
		padding-top: 50px;
	}

	.small-footer {
		max-width: 980px !important;
	}

	&.fixed {
		padding-top: 88px;
	}
}
