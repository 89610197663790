.container {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 40px;
  @media screen and (max-width: 1200px) {
    padding: 0 22px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
}
