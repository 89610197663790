.wrapper {
	margin-bottom: 150px;
	padding-top: 80px;
	@media screen and (max-width: 768px) {
		margin-bottom: 50px;
		padding-top: 30px;
	}

	.container {
		max-width: 980px;
		padding: 0 22px;
		@media screen and (max-width: 768px) {
			padding: 0 16px;
		}
	}
}
