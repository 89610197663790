.wrapper {
	margin-bottom: 150px;
	padding-top: 130px;
	@media screen and (max-width: 768px) {
		margin-bottom: 50px;
		padding-top: 30px;
	}

	.title {
		color: #323037;
		font-size: 203px;
		font-weight: 600;
		line-height: 244px;
		margin-bottom: 25px;
		text-align: center;
		@media screen and (max-width: 992px) {
			font-size: calc(107px + 96 * ((100vw - 480px) / (992 - 480)));
			line-height: calc(128px + 116 * ((100vw - 480px) / (992 - 480)));
		}
		@media screen and (max-width: 768px) {
			margin-bottom: 20px;
		}
		@media screen and (max-width: 480px) {
			font-size: 107px;
			line-height: 128px;
		}
	}

	.text {
		color: #323037;
		font-size: 24px;
		font-weight: 500;
		line-height: 29px;
		text-align: center;

		a {
			color: #39d292;
		}
		@media screen and (max-width: 992px) {
			font-size: calc(16px + 8 * ((100vw - 480px) / (992 - 480)));
			line-height: calc(19px + 10 * ((100vw - 480px) / (992 - 480)));
		}
		@media screen and (max-width: 480px) {
			font-size: 16px;
			line-height: 19px;
		}
	}
}

// .wrapper {
//   margin-bottom: 150px;
//   @media screen and (max-width: 768px) {
//     margin-bottom: 100px;
//   }

//   .container {
//     @media screen and (max-width: 1200px) {
//       padding: 0 22px;
//     }
//   }

//   .imageWrapper {
//     margin-bottom: 150px;
//     width: 100%;
//     @media screen and (max-width: 768px) {
//       margin-bottom: 100px;
//     }

//     img {

//       border-radius: 34px;
//       height: auto;
//       width: 100%;
//     }

//     .title {

//       color: #fff;
//       font-size: 72px;
//       font-weight: 600;
//       line-height: 86px;
//       padding: 0 75px 45px;

//       transform: translateY(-100%);
//       @media screen and (max-width: 1200px) {
//         font-size: 48px;
//         line-height: 58px;
//       }
//     }
//   }
// }
