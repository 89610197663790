.wrapper {
  .title {

    color: #323037;
    font-size: 64px;
    letter-spacing: -0.03em;
    line-height: 70px;
    margin-bottom: 75px;
    text-align: center;

    @media screen and (max-width: 992px) {
      font-size: calc(28px + 36 * ((100vw - 480px) / (992 - 480)));
      line-height: calc(30px + 40 * ((100vw - 480px) / (992 - 480)));
    }
    @media screen and (max-width: 480px) {

      font-size: 28px;
      line-height: 30px;
      margin-bottom: 50px;
    }
  }

  .inner {
    display: grid;
    grid-gap: 75px 20px;
    grid-template-columns: repeat(3, 1fr);
    @media screen and (max-width: 1200px) {
      grid-gap: 50px 16px;
    }
    @media screen and (max-width: 992px) {
      grid-gap: 32px 16px;
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 768px) {
      grid-gap: 32px;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
