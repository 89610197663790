.wrapper {
	margin-bottom: 150px;

	padding-top: 80px;

	.container {
		box-sizing: content-box;
		max-width: 980px;
		@media screen and (max-width: 1024px) {
			box-sizing: border-box;
		}
	}

	.title {
		align-content: start;
		display: grid;
		grid-gap: 36px;
		grid-template-columns: 1fr 70px;
		margin-bottom: 50px;

		h1 {
			color: #323037;
			font-size: 64px;
			font-weight: 500;
			line-height: 70px;
			@media screen and (max-width: 992px) {
				font-size: calc(32px + 32 * ((100vw - 480px) / (992 - 480)));
				line-height: calc(38px + 32 * ((100vw - 480px) / (992 - 480)));
			}
			@media screen and (max-width: 480px) {
				font-size: 32px;
				line-height: 38px;
			}
		}

		img {
			height: auto;
			width: 70px;
			@media screen and (max-width: 768px) {
				grid-row: 1;
				width: 54px;
			}
		}
		@media screen and (max-width: 768px) {
			grid-gap: 20px;
			grid-template-columns: 1fr;
		}
	}

	.text {
		color: #323037;
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 80px;
		white-space: pre-wrap;
		@media screen and (max-width: 992px) {
			margin-bottom: 60px;
		}
	}

	.block {
		margin-bottom: 80px;

		h2 {
			color: #323037;
			font-size: 48px;
			font-weight: 500;
			letter-spacing: -0.005em;
			line-height: 50px;
			margin-bottom: 30px;
			@media screen and (max-width: 992px) {
				font-size: calc(24px + 24 * ((100vw - 480px) / (992 - 480)));
				line-height: calc(29px + 21 * ((100vw - 480px) / (992 - 480)));
				margin-bottom: 25px;
			}
			@media screen and (max-width: 480px) {
				font-size: 24px;
				line-height: 29px;
			}
		}

		ul {
			li {
				color: #323037;
				font-size: 18px;
				line-height: 22px;

				margin-bottom: 15px;
				padding-left: 15px;
				position: relative;

				&::after {
					background: #737373;
					border-radius: 50%;

					content: '';

					display: block;
					height: 5px;
					left: 0;
					position: absolute;
					top: 8.5px;
					width: 5px;
				}
				@media screen and (max-width: 992px) {
					font-size: calc(16px + 2 * ((100vw - 480px) / (992 - 480)));
					line-height: calc(19px + 3 * ((100vw - 480px) / (992 - 480)));
				}
				@media screen and (max-width: 480px) {
					font-size: 16px;
					line-height: 19px;
				}
			}
		}
		@media screen and (max-width: 992px) {
			margin-bottom: 60px;
		}
	}

	.file {
		color: #323037;
		margin-bottom: 30px;

		label {
			align-items: center;

			color: #000;

			cursor: pointer;
			display: flex;
			font-size: 20px;
			font-weight: 500;
			line-height: 24px;

			svg {
				height: 40px;
				width: 40px;
			}
		}

		&:placeholder {
			color: #737373;
		}

		input {
			left: -999vw;

			opacity: 0;
			position: absolute;
		}
	}

	.agree {
		color: #323037;
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 30px;
		padding: 8px 0;

		a {
			color: #737373;
			text-decoration: underline;
			display: inline;
		}
		@media screen and (max-width: 768px) {
			margin-top: 10px;
			padding: 0;

			text-align: center;
		}
	}

	.form {
		max-width: 380px;

		input[type='text'] {
			background: #fff;

			border: 1px solid transparent;
			border-radius: 61px;

			color: #737373;
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 25px;
			padding: 20px 24px;
			width: 100%;

			&.error {
				border: 1px solid #f06d43;

				transition: ease 0.5s;
			}
			@media screen and (max-width: 768px) {
				margin-bottom: 20px;
			}
			@media screen and (max-width: 360px) {
				margin-bottom: 15px;
			}
		}

		textarea {
			background: #fff;

			border: 1px solid transparent;
			border-radius: 24px;

			color: #737373;
			font-size: 20px;
			height: 160px;
			line-height: 24px;
			margin-bottom: 25px;
			padding: 20px 24px;

			resize: none;
			width: 100%;

			&.error {
				border: 1px solid #f06d43;

				transition: ease 0.5s;
			}
		}

		button {
			background: #323037;
			border: 0;
			border: 2px solid #323037;
			border-radius: 40px;

			color: #fff;
			cursor: pointer;
			font-size: 20px;
			font-weight: 600;
			line-height: 18px;
			padding: 23px;
			text-align: center;

			transition: ease 0.5s;
			width: 100%;

			&:hover {
				background: transparent;
				border: 2px solid #323037;
				color: #323037;

				transition: ease 0.5s;
			}
		}
	}
	@media screen and (max-width: 768px) {
		margin-bottom: 50px;
		padding-top: 30px;
	}
}
