.wrapper {
	box-sizing: content-box;
	max-width: 980px;
	padding: 0;

	.title {
		color: #bab9bb;
		font-size: 56px;
		letter-spacing: -0.03em;
		line-height: 70px;
		margin-bottom: 25px;
		@media screen and (max-width: 992px) {
			font-size: calc(24px + 32 * ((100vw - 480px) / (992 - 480)));
			line-height: calc(29px + 41 * ((100vw - 480px) / (992 - 480)));
			margin-bottom: 10px;
		}
		@media screen and (max-width: 480px) {
			font-size: 24px;
			line-height: 29px;
		}
	}

	.title-wrapper {
		max-width: 980px;
		padding: 0;
	}

	.inner {
		margin-left: -40px;
		width: calc(100% + 40px);
		@media screen and (max-width: 992px) {
			margin-left: -30px;
			width: calc(100% + 30px);
		}
		@media screen and (max-width: 768px) {
			margin-left: -20px;
			width: calc(100% + 20px);
		}

		.slider-item {
			align-items: center;

			display: flex !important;
			justify-content: center;
			position: relative;

			img {
				display: block;
				max-height: 50px;
				width: auto;
			}


			.hover {
				bottom: 0;
				left: 0;

				opacity: 0;
				position: absolute;
				right: 0;
				top: 0;
			}

			&:hover {
				.hover {
					opacity: 1;
					z-index: 5;
				}
			}
		}
	}

	&.full {
		max-width: inherit !important;

		.inner {
			margin-left: -40px;
			margin-right: -40px;
			width: calc(100% + 80px);
			@media screen and (max-width: 992px) {
				margin-left: -30px;
				margin-right: -30px;
				width: calc(100% + 60px);
			}
			@media screen and (max-width: 768px) {
				margin-left: -20px;
				margin-right: -20px;
				width: calc(100% + 40px);
			}

			img {
				max-height: 100%;
			}
		}
	}
}
