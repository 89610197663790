.wrapper {
  margin-bottom: 150px;
  padding-top: 150px;
  @media screen and (max-width: 768px) {
    margin-bottom: 100px;
    padding-top: 80px;
  }

  .text-container {
    margin-bottom: 150px;
    max-width: 1024px;
    padding: 0 22px;
    @media screen and (max-width: 1200px) {
      max-width: 800px;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 100px;
      padding: 0 16px;
    }

    .title {

      color: #323037;
      font-size: 100px;
      font-weight: 600;
      letter-spacing: -0.03em;
      line-height: 88%;
      margin-bottom: 32px;
      @media screen and (max-width: 992px) {

        font-size: calc(44px + 56 * ((100vw - 480px) / (992 - 480)));
        margin-bottom: 25px;
      }
      @media screen and (max-width: 480px) {

        font-size: 44px;
        margin-bottom: 15px;
        text-align: center;
      }
    }

    .text {
      color: #737373;
      font-size: 32px;
      line-height: 42px;
      @media screen and (max-width: 992px) {
        font-size: calc(16px + 16 * ((100vw - 480px) / (992 - 480)));
        line-height: calc(19px + 23 * ((100vw - 480px) / (992 - 480)));
      }
      @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 19px;
        text-align: center;
      }
    }
  }
}
