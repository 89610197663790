.wrapper-wrapper {
	width: 100vw;
	@media screen and (max-width: 768px) {
		padding: 0;
	}
}

.wrapper {
	background: #fff;
	border-radius: 40px;
	margin-bottom: 150px;
	padding: 75px 40px;
	@media screen and (max-width: 992px) {
		padding: 50px 30px;
	}
	@media screen and (max-width: 768px) {
		border-radius: 16px;
		margin-bottom: 100px;
		padding: 40px 20px;
	}

	> *:last-child {
		margin-bottom: 0 !important;
	}

	.title {
		color: #323037;
		font-size: 64px;
		letter-spacing: -0.03em;
		line-height: 77px;
		margin-bottom: 75px;
		text-align: center;

		span {
			color: #39d292;
			display: inline-block;
			margin-right: 10px;
		}
		@media screen and (max-width: 992px) {
			font-size: calc(28px + 36 * ((100vw - 480px) / (992 - 480)));
			line-height: calc(30px + 37 * ((100vw - 480px) / (992 - 480)));
			margin-bottom: 10px;
		}
		@media screen and (max-width: 480px) {
			font-size: 28px;
			line-height: 30px;
		}
	}

	.slider-up {
		margin-bottom: 80px;
		@media screen and (max-width: 992px) {
			margin-bottom: 20px;
		}

		img {
			height: 80px;
			max-height: 80px !important;
			padding: 0 12px;
			max-width: 240px;
			object-fit: contain;
			@media screen and (max-width: 480px) {
				padding: 0;
			}
		}
	}

	.slider-down {
		min-width: 140px;
		img {
			height: 80px;
			max-height: 80px !important;
			padding: 0 12px;
			object-fit: contain;
			max-width: 240px;
			
			@media screen and (max-width: 480px) {
				padding: 0;
			}
		}
	}

	.slider-item {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
