.wrapper {
  box-sizing: content-box;
  margin-bottom: 90px;
  max-width: 980px;
  padding: 0;
  @media screen and (max-width: 992px) {
    margin-bottom: 40px;
  }

  .title {

    color: #bab9bb;
    font-size: 56px;
    letter-spacing: -0.03em;
    line-height: 70px;
    margin-bottom: 5px;

    @media screen and (max-width: 992px) {
      font-size: calc(24px + 32 * ((100vw - 480px) / (992 - 480)));
      line-height: calc(29px + 41 * ((100vw - 480px) / (992 - 480)));
    }
    @media screen and (max-width: 480px) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  .inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 55px;
    @media screen and (max-width: 768px) {
      grid-gap: 20px;
      grid-template-columns: 1fr;
      margin: 0 auto;
      max-width: 480px;
      padding-bottom: 0;
    }
    @media screen and (max-width: 480px) {
      max-width: 90%;
      min-width: 270px;
    }

    .item {

      align-self: start;

      border-radius: 32px;
      // box-shadow: 0 6px 16px rgba(81, 54, 85, 0.16);

      max-width: 507px;
      padding: 40px 64px 50px;
      position: relative;

      @media screen and (max-width: 992px) {

        border-radius: 16px;
        padding: 20px 20px 30px;
        width: 100%;
      }

      &-name {

        color: #323037;
        font-size: 28px;
        font-weight: 500;
        line-height: 34px;
        margin-bottom: 5px;
        @media screen and (max-width: 992px) {
          font-size: calc(16px + 12 * ((100vw - 480px) / (992 - 480)));
          line-height: calc(19px + 15 * ((100vw - 480px) / (992 - 480)));
        }
        @media screen and (max-width: 480px) {
          font-size: 16px;
          line-height: 19px;
        }
      }

      &-role {

        color: #737373;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 30px;
        @media screen and (max-width: 992px) {

          font-size: calc(14px + 6 * ((100vw - 480px) / (992 - 480)));
          line-height: calc(17px + 7 * ((100vw - 480px) / (992 - 480)));
          margin-bottom: 15px;
        }
        @media screen and (max-width: 480px) {
          font-size: 14px;
          line-height: 17px;
        }
      }

      &-text {

        color: #323037;
        font-size: 28px;
        font-weight: 500;
        line-height: 34px;
        margin-bottom: 30px;
        @media screen and (max-width: 992px) {

          font-size: calc(16px + 12 * ((100vw - 480px) / (992 - 480)));
          line-height: calc(19px + 15 * ((100vw - 480px) / (992 - 480)));
          margin-bottom: 15px;
        }
        @media screen and (max-width: 480px) {
          font-size: 16px;
          line-height: 19px;
        }
      }

      &-icon {
        align-items: center;
        display: flex;
        height: 100px;
        justify-content: center;
        margin: -10px 0;
        @media screen and (max-width: 992px) {
          height: 65px;
        }
        @media screen and (max-width: 480px) {
          height: 45px;
        }
      }

      img {
        display: block;
        height: 100%;
        width: auto;
      }

      &:hover {
        z-index: 5;
      }

      &:nth-child(1) {
        left: 50px;
        top: 50px;

        transform: rotate(-15deg);
      }

      &:nth-child(2) {
        right: 30px;
        top: 120px;

        transform: rotate(10.43deg);
      }

      &:nth-child(3) {
        left: 30px;

        transform: rotate(-5.1deg);
      }

      &:nth-child(4) {
        right: 70px;

        transform: rotate(17.81deg);
      }

      @media screen and (max-width: 768px) {
        bottom: inherit !important;
        left: inherit !important;
        margin-top: 0;

        max-width: 100%;
        padding-bottom: 50px;

        pointer-events: none !important;
        right: inherit !important;
        top: inherit !important;

        &:nth-child(1) {
          left: 8px !important;

          transform: rotate(-4.93deg);
        }

        &:nth-child(2) {

          margin-top: -50px;
          right: -30px !important;

          transform: rotate(5.27deg);
        }

        &:nth-child(3) {
          left: -5px !important;

          margin-top: -50px;

          transform: rotate(-6.28deg);
        }

        &:nth-child(4) {

          margin-top: -50px;
          padding-bottom: 30px;
          right: -10px !important;

          transform: rotate(4.93deg);
        }
      }
    }
  }
}
